body {
  font-family: "Montserrat";
  margin: 0px;
  background-color: #FAFAFA;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

svg {
  display:block;
}

a {
  text-decoration: none;
  color: black;
}

figure {
  padding: 0px;
  margin: 0px;
}

.App {
  display: flex;
  flex-direction: column;
}

.AppLogo {
  width: 190px;
}

.NavContainer {
  display: flex;
  justify-content: center;
}

.Nav {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0px;
  min-width: 1052px;
}

#NavLinks {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-size: 17px;
  font-weight: 400;
  /* width: 500px; */
  column-gap: 30px;
  /* justify-content: space-between; */
}

#NavLinks div:hover {
  color: #423bd1;
}

#NavLinks a {
  color: inherit;
}

#RowAContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin: 20px 25px; */
  width: 100%;
  justify-content: center;
}

#RowA {
  display: flex;
  flex-direction: row;
  max-width: 1052px;
}

#RowALeft {
  display: flex;
  flex-direction: column;
  margin: 60px 0;
}

#RowARight {
  content: url(../images/PhoneAppMockup.png);
  max-width: 580px;
  object-fit: cover;
}

.Heading {
  font-size: 36px;
  font-weight: 700;
  color: #423bd1;
}

.Heading2 {
  font-size: 34px;
  font-weight: 700;
  color: #423bd1;
  margin: 5px 0px 0px;
}

.Heading3 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0px 0px;
}

.SubHeading {
  font-size: 17px;
  font-weight: 400;
  color: #2a293d;
  margin: 18px 5px;
  line-height: 26px;
}

#AppStoreButton {
  margin: 20px 0;
  width: 170px;
}

#RowBContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: #fee9e9;
  box-shadow: 0 4px 20px rgba(0,0,0,.05);
  margin: 30px 0 30px 0;
}

#RowB {
  display: flex;
  flex-direction: column;
  background-color: #fee9e9;
  align-items: center;
  text-align: center;  
  padding: 38px;
}

#RowB img {
  width: 100px;
}

#RowCContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 25px 0px;
}

.Pillars {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-top: 65px;
}

.Pillar {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  margin-bottom: 60px;
}

.Pillar img {
  object-fit: contain;
  max-height: 400px;
  flex-grow: 1;
}

.PillarContent {
  display: flex;
  flex-direction: column;
}

.PillarName {
  font-size: 28px;
  font-weight: 700;
  color: #423bd1;
  color: black;
  margin: 8px 0;
}

.Spacer {
  min-width: 35px;
}

#AppStoreButtonSmall {
  margin: 10px 0;
  width: 120px;
}

#RowDContainer {
  text-align: center;
}

#RowD {
  display: flex;
  margin: 30px 0 40px 0;
  background-color: #fee9e9;
  box-shadow: 0 4px 20px rgba(0,0,0,.05);
  min-height: 240px;
  justify-content: center;
}

.Benefits {
  display: flex;
  flex-direction: row;
  margin: 30px;
}

.Benefit {
  margin: 30px 20px;
  flex: 1;
}

.Benefit img {
  object-fit: contain;
  max-height: 60px;
}

#RowE {
  display: flex;
  flex-direction: column;
  margin: 50px 0px 0px 0px;
  align-items: center;
  text-align: center;
}

#RowF {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fee9e9;
  padding: 40px 0;
  margin: 90px 0 10px 0;
  box-shadow: 0 4px 20px rgba(0,0,0,.05);
}

.Email {
  position: relative;
  margin: 26px 0px 25px;
}

.Input {
  height: 70px;
  min-width: 500px;
  max-width: 550px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 5px 5px 10px #0012;
  border-radius: 15px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#EmailSubmitBtn {
  position: absolute;
  top: 13px;
  right: 13px;
  background-image: url(../images/Arrow.png);
  background-repeat: no-repeat;
  background-position: center; 
  background-color: #ff5555;
  background-size: 22px;
  width: 48px;
  height: 48px;
  border-radius: 24px;
  cursor: pointer;
  margin-left: 10px;
}

#EmailSubmitMessage {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ff5555;
  line-height: 25px;
  margin-top: 25px;
  font-style: italic;
}

#EmailError {
  color: #ff5555;
}

#Footer {
  display: flex;
  flex-direction: column;
  margin: 50px 0 0 0 ;
  padding-bottom: 100px;
  align-items: center;
  text-align: center;
}

#Social img {
  height: 45px;
  margin: 8px;
}

#Contact {
  color: #423bd1;
}

.Links {
  margin-top: 40px;
  font-size: 12px;
}

#Copyright {
  margin-top: 10px;
  font-size: 12px;
}

.BlogListGrid {
  max-width: 1200px;
  display: grid;
  margin-left: -2rem;
  column-gap: 2em;
  row-gap: 2em;
  grid-template-columns: repeat(3, 1fr);
}

.BlogCard {
  border-radius: 25px;
  width: 400px;
  height: auto;
  margin: 10px 0 5px 0;
  text-align: left;
  box-shadow: 0 4px 20px rgba(0,0,0,.05);
  overflow:hidden;
  cursor: pointer;
}

.BlogCard img {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}

#Blog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:  10px 30px 30px 30px;
}

#Blog.dark {
  background-color: #141330;
  color: white;
}

#Blog.dark a {
  color: white;
}

.BlogContent {
  display: flex;
  flex-direction: column;
  max-width: 1052px;
  font-size: 18px;
}

.BlogContent img {
  max-width: 1052px;
  border-radius: 20px;
}

.BlogContent em {
  font-weight: bold;
}

.BlogContent a {
  text-decoration: underline;
}

.BlogHeader {
  font-size: 32px;
  margin: 40px 0px;
  font-weight: 500;
  text-align: center;
}

.BlogSubTitle {
  font-size: 22px;
  margin: 40px 0px 10px;
  font-weight: 500;
  text-align: center;
}

/*  Loading indicator */

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

#Spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  right: 27px;
  width: 25px;
  height: 25px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border-top: 2px solid #ff5555;
  border-right: 2px solid transparent;
  animation: spinner 0.6s linear infinite;
}

#MediumWidget {
  background-color: #fee9e9;
  padding: 30px 40px;
}

/******** Tablet *********/

@media (min-width : 768px) and (max-width : 1024px) {

  .AppLogo {
    /* width: 190px; */
  }

  .Nav {
    min-width: 750px;
  }

  #NavLinks {
    margin: 0 10px 0 20px;
    font-size: 16px;
  } 

  #RowALeft {
    margin: 10px 10px 20px 35px;
    max-width: 420px;
  }

  #RowARight {
    align-self: flex-start;
    max-width: 420px;
    margin-top: 35px;
  }

  .Heading {
    font-size: 32px;
  }

  .SubHeading {
    font-size: 16px;
  }

  .Pillars {
    flex-direction: column;
    max-width: 660px;
  }

  .Pillar img {
    max-height: 330px;
  }

  .Benefits {
    margin: 10px;
  }

  .BlogListGrid { 
    margin-left: 0rem;
    column-gap: 0em;
    grid-template-columns: repeat(1, 1fr) 
  }

  .BlogContent {
    max-width: 767px;
  }

  .BlogContent img {
    max-width: 100%;
  }

  .BlogHeader {
    font-size: 24px;
  }

  .BlogSubTitle {
    font-size: 20px;
  }

  .BlogCard {
    width: 500px;
  }
}


/******** Mobile *********/

@media (max-width: 767px) {

  .AppLogo {
    width: 190px;
  }

  .Nav {
    min-width: 100px;
    justify-content: center;
  }

  #NavLinks {
    display: none;
  }

  #RowA {
    flex-direction: column;
    align-items: center;
  }

  #RowALeft {
    margin: 0 25px;
  }
  
  #RowARight {
    margin-top: 30px;
    max-width: 100%;
  }

  .Heading {
    font-size: 28px;
  }

  .SubHeading {
    font-size: 17px;
  }

  #AppStoreButton {
    align-self: center;
    margin-top: 30px;
  }

  .Pillars, .Benefits {
    flex-direction: column;
  }

  .Pillar {
    flex-direction: column;
  }
  
  .PillarReverse {
    flex-direction: column-reverse;
  }

  .Benefit {
    display: flex;
    margin: 10px 10px;
    flex-direction: row;
    text-align: left;
    align-items: center;
  }

  .Benefit img {
    max-height: 35px;
    margin-right: 15px;
  }

  .Pillar img {
    max-height: 300px;
  }

  .PillarName {
    font-size: 24px;
    text-align: center;
  }

  .Input {
    min-width: 300px;
  }

  .Heading2 {
    font-size: 28px;
  }

  .medium-widget-article__row {
    flex-direction: column;
  }

  .BlogListGrid { 
    grid-template-columns: repeat(1, 1fr); 
    margin-left: 0rem;
    column-gap: 0em;
  }

  .BlogContent {
    max-width: 350px;
  }

  .BlogContent img {
    max-width: 100%;
  }

  .BlogHeader {
    font-size: 24px;
    margin: 0;
    padding: 10px 10px 30px 10px;
    max-width: 360px;
  }

  .BlogSubTitle {
    font-size: 20px;
  }

  .BlogCard {
    width: 340px;
  }
}

/******** Animations ********/

.AnimatedSection {
  position: relative;
  animation-duration: 1s;
  animation-name: slideIn;
}

@keyframes slideIn  {
  from {
    opacity: 0.3;
    transform: translateX(4vh);
  }
  
  to {
    opacity: 100;
    transform: none;
  }
}

.FadeInSection {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-out, transform 2s ease-out;
  will-change: opacity, visibility;
}

.FadeInSection.left {
  transform: translateX(-4vh);
}

.FadeInSection.right {
  transform: translateX(4vh);
}

.FadeInSection.isVisible.left, .FadeInSection.isVisible.right  {
  opacity: 1;
  transform: none;
  visibility: visible;
}